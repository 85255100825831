/**
 * Show preText like 'Gekozen filters' only when the filters are selected
 * If the FWP.facets( object ) contains filters that are not empty show the preText
 */
(function ($) {
  $(document).on('facetwp-loaded', function () {
    var preTextEl = $('.facetwp-selections__pretext')

    if (FWP.loaded && FWP.facets) {
      var showFacetFilterText = false

      $.each(FWP.facets, function (i, facet) {
        if (facet.length > 0) {
          showFacetFilterText = true
        }
      })

      showFacetFilterText ? preTextEl.addClass('d-flex') : preTextEl.removeClass('d-flex')
    }
  })
})(jQuery)
